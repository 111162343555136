import { FormEvent, ReactNode, useState } from "react";
// import { Link } from "react-router-dom";
import { BillingRuleForm } from "../gateways/cms/billingRule";
import { Entity } from "../gateways/cms/id";
import { Organization, OrganizationForm as OrganizationFormData } from "../gateways/cms/organization";
import { BillingRuleEditModal } from "./BillingRuleEditModal";
import { OrganizationBillingDataDownloadForm } from "./OrganizationBillingDataDownloadForm";
import { SelectOrganization } from "./SelectOrganization";
import { SelectOrganizationModal } from "./SelectOrganizationModal";
import { SelectUser } from "./SelectUser";
import { SelectUserModal } from "./SelectUserModal";
import { ItemSummaryPop } from "../components/itemSummaryPop";

import { styled } from "@mui/system";
import Button from "@mui/material/Button";
// import Grid from "@mui/material/Grid";
import VarConst from "../styles/variables.js";
import iconPen from "../assets/icons/icon_pen.svg";
import iconDelete from "../assets/icons/icon_delete.svg";
import { getAddress } from "../gateways/shared/zipcloud";

// style
const FormModified = styled("form")`
    padding-bottom: 64px;
`;
const SectionTitle = styled("h2")`
    margin: 0 0 24px;
    padding: 10px 10px 10px 40px;
    height: 40px;
    font-weight: normal;
    font-size: 20px;
    background-color: #f4f4f4;
    line-height: 1;

    & + div,
    & + ul {
        /* width: 100%; */
        margin: 24px 24px 48px;

        input,
        select {
            height: 32px;
            border: ${VarConst.border1};
        }

        input[type="text"] {
            padding-left: 0.75em;
        }
        input[type="date"],
        input[type="time"] {
            padding-left: 0.5em;
        }
        label {
            display: flex;
            margin-bottom: 8px;

            & ~ input[type="text"],
            & + select {
                width: 100%;
            }
            & input[type="date"] {
                width: 12em;
            }
            & input[type="time"] {
                width: 6em;
            }
        }
    }
`;
const InputBasic = styled("ul")`
    display: grid;
    grid-template-columns: 12.5% 12.5% 25% 40% 10%;

    & > li {
        margin-bottom: 24px;
        &:nth-of-type(1) {
            grid-row: 1/2;
            grid-column: 1/2;
        }
        &:nth-of-type(2) {
            grid-row: 1/2;
            grid-column: 2/4;
            padding-left: 24px;
        }
        &:nth-of-type(3) {
            grid-row: 2/3;
            grid-column: 1/2;
        }
        &:nth-of-type(4) {
            grid-row: 2/3;
            grid-column: 2/3;
            padding-left: 24px;
            display: flex;
            align-items: end;
        }
        &:nth-of-type(5) {
            grid-row: 3/4;
            grid-column: 1/4;
        }
        &:nth-of-type(6) {
            grid-row: 4/5;
            grid-column: 1/3;
            padding-right: 12px;
        }
        &:nth-of-type(7) {
            grid-row: 4/5;
            grid-column: 3/4;
            padding-left: 12px;
        }
        &:nth-of-type(8) {
            grid-row: 5/6;
            grid-column: 1/5;
            margin-bottom: 16px;
        }
        &:nth-of-type(9) {
            grid-row: 6/7;
            grid-column: 1/5;
            margin-bottom: 16px;
        }
        &:nth-of-type(10) {
            grid-row: 7/8;
            grid-column: 1/5;
            margin-bottom: 16px;
        }
        &:nth-of-type(11) {
            grid-row: 8/9;
            grid-column: 1/4;
            padding-right: 12px;
            margin-bottom: 16px;
        }
        &:nth-of-type(12) {
            grid-row: 8/9;
            grid-column: 4/5;
            padding-left: 12px;
            margin-bottom: 16px;
        }

        label {
            display: block;
        }
    }
`;

const InputMonthly = styled("ul")`
    & > li {
        display: flex;
        margin-bottom: 24px;

        input[type="text"] {
            margin-right: 16px;
            &:nth-of-type(1) {
                width: 180px;
            }
            &:nth-of-type(2) {
                width: 384px;
            }
            &:nth-of-type(3) {
                width: 90px;
            }
            &:nth-of-type(4) {
                width: 180px;
            }
        }
        button + button {
            margin-left: 16px;
        }
    }
`;

const FunctionHeader = styled("header")`
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    label {
        margin-bottom: 0 !important;
    }
    button {
        margin-left: 8px;
    }
`;
const SimpleList = styled("ul")`
    display: flex;
    flex-wrap: wrap;
`;
const SimpleListItem = styled("li")`
    position: relative;
    margin-right: 24px;
    margin-bottom: 24px;
    background-color: #f4f4f4;
    border-radius: 2px;
    width: 180px;

    select {
        max-width: 11.25em;
        padding: 0 4px 0 8px;
        appearance: none;
        border: none !important;
        background-color: transparent;
        pointer-events: none;
        margin-bottom: 0;
    }
    .MuiButton-root {
        position: absolute !important;
        right: 0;
        top: 0;
        min-width: 0;
        padding: 0;
    }
`;
const ButtonEdit = styled("button")`
    height: 24px;
    width: 24px;
    min-width: 24px;
    border: ${VarConst.border1};
    border-width: 2px;
    border-radius: 2px;
    background-image: url(${iconPen});
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #fff;
    overflow: hidden;
    color: transparent;
    user-select: none;
    cursor: pointer;
    border-color: transparent;
`;
const ButtonDelete = styled("button")`
    height: 24px;
    width: 24px;
    min-width: 24px;
    border: ${VarConst.border1};
    border-width: 2px;
    border-radius: 2px;
    background-image: url(${iconDelete});
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #fff;
    overflow: hidden;
    color: transparent;
    user-select: none;
    cursor: pointer;
    border-color: transparent;
`;
const ButtonAddRule = styled(Button)`
    display: block;
    margin: 0 auto;
    padding: 0;
    text-align: center;

    border-width: 2px;
    border-radius: 2px;

    overflow: hidden;
    color: transparent;
    user-select: none;
    cursor: pointer;
    border-color: transparent;

    position: relative;
    left: 600px;
    top: -20px;

    &.MuiButton-contained {
        height: 32px;
        width: 32px;
        min-width: 32px;
        border-radius: 2px;
        color: #f4f4f4;
        background-color: #3a3e5d;
        border-color: #3a3e5d;
    }
`;
const ButtonZip = styled(Button)`
    height: 32px;
    width: 116px;
    padding: 4px;

    &.MuiButton-outlined {
        background-color: #fff;
        border-width: 2px;
        border-radius: 2px;
        border-color: #c9cbd3;
        color: inherit;
    }
`;

export interface Props {
    children?: ReactNode;
    organization?: Organization;
    onSubmit: (data: OrganizationFormData) => void;
}

export function OrganizationForm({ children, organization, onSubmit }: Props) {
    const [name, setName] = useState(organization?.name || "");
    const [zip, setZip] = useState(organization?.address.zip || "");
    const [address, setAddress] = useState(organization?.address.address || "");
    const [tel1, setTel1] = useState(organization?.address.tel1 || "");
    const [tel2, setTel2] = useState(organization?.address.tel2 || "");
    const [users, setUsers] = useState<Entity[]>(organization?.user ?? []);
    const [managers, setManagers] = useState<Entity[]>(organization?.manager ?? []);
    const [fileServer, setFileServer] = useState(organization?.fileServer || "");
    const [endpoint, setEndpoint] = useState(organization?.endpoint || "");
    const [organizationChildren, setOrganizationChildren] = useState<Entity[]>(organization?.childOrganization ?? []);
    const [billingRules, setBillingRules] = useState<BillingRuleForm[]>(organization?.billingRules ?? []);
    const [selectUserModalOpen, setSelectUserModalOpen] = useState(false);
    const [selectManagerModalOpen, setSelectManagerModalOpen] = useState(false);
    const [selectOrganizationModalOpen, setSelectOrganizationModalOpen] = useState(false);
    const [selectedBillingRuleIndex, selectBillingRuleIndex] = useState(-1);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if(!name){
            alert("テナント名を設定してください");
            return;
        }
        else if(!fileServer){
            alert("ファイルサーバーを設定してください");
            return;
        }
        else{
            onSubmit({
                id: organization?.id,
                address: {
                    address,
                    zip,
                    tel1,
                    tel2,
                },
                name,
                user: users,
                manager: managers,
                fileServer: fileServer,
                endpoint: endpoint,
                childOrganization: organizationChildren,
                billingRules,
            });
        }
    };

    const changeBillingRule = (newRule: BillingRuleForm) => {
        setBillingRules(billingRules.map((rule, i) => (i === selectedBillingRuleIndex ? newRule : rule)));
        selectBillingRuleIndex(-1);
    };

    const addBillingRule = () => {
        setBillingRules([
            ...billingRules,
            {
                name: "請求ルール",
                remarks: "",
                periodFrom: {
                    monthFlg: 0,
                    day: 15,
                },
                periodTo: {
                    monthFlg: 1,
                    day: 15,
                },
                packPrice: 0,
                contentsMax: 0,
                overageCharges: 0,
            },
        ]);
    };

    const removeBillingRule = (index: number) => {
        setBillingRules(billingRules.filter((_, i) => i !== index));
    };

    const handleZipToAddress = () => {
        if (!zip) return;
        getAddress(zip).then((address) => {
            console.log(address);
            setAddress(address);
        });
    };

    return (
        <>
            <FormModified onSubmit={handleSubmit}>
                <section>
                    <SectionTitle>基本情報</SectionTitle>
                    <InputBasic>
                        <li>
                            <label htmlFor="id">管理ID</label>
                            <input type="text" value={organization?.id} id="id" disabled />
                        </li>
                        <li>
                            <label htmlFor="name">テナント名</label>
                            <input type="text" value={name} id="name" onChange={(e) => setName(e.target.value)} />
                        </li>
                        <li>
                            <label htmlFor="zip">郵便番号</label>
                            <input type="text" value={zip} id="zip" onChange={(e) => setZip(e.target.value)} />
                        </li>
                        <li>
                            <ButtonZip type="button" variant="outlined" onClick={handleZipToAddress}>住所検索</ButtonZip>
                        </li>
                        <li>
                            <label htmlFor="address">住所</label>
                            <input
                                type="text"
                                value={address}
                                id="address"
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </li>
                        <li>
                            <label htmlFor="tel1">電話番号 1</label>
                            <input type="text" value={tel1} id="tel1" onChange={(e) => setTel1(e.target.value)} />
                        </li>
                        <li>
                            <label htmlFor="tel2">電話番号 2</label>
                            <input type="text" value={tel2} id="tel2" onChange={(e) => setTel2(e.target.value)} />
                        </li>
                        <li>
                            <FunctionHeader>
                                <label>所属テナント</label>
                                <ButtonEdit type="button" onClick={() => setSelectOrganizationModalOpen(true)}>
                                    編集
                                </ButtonEdit>
                            </FunctionHeader>

                            <SimpleList>
                                {organizationChildren.map((organization) => (
                                    <SimpleListItem key={organization.id}>
                                        <SelectOrganization value={organization.id} />
                                        <ItemSummaryPop
                                            popTitle={<SelectOrganization value={organization.id} />}
                                            popMain={<div>グループ詳細 未取得</div>}
                                            linkDetail={`/organizations/${organization.id}/edit`}
                                        />
                                    </SimpleListItem>
                                ))}
                            </SimpleList>
                        </li>
                        <li>
                            <FunctionHeader>
                                <label>テナント管理者</label>
                                <ButtonEdit type="button" onClick={() => setSelectManagerModalOpen(true)}>
                                    編集
                                </ButtonEdit>
                            </FunctionHeader>

                            <SimpleList>
                                {managers.map((manager) => (
                                    <SimpleListItem key={manager.id}>
                                        <SelectUser value={String(manager.id)} disabled={false} />
                                        <ItemSummaryPop
                                            popTitle={<SelectUser value={String(manager.id)} disabled={false} />}
                                            popMain={<div>テナント管理者詳細 未取得</div>}
                                            linkDetail={`/users/${manager.id}/edit`}
                                        />
                                    </SimpleListItem>
                                ))}
                            </SimpleList>
                        </li>

                        <li>
                            <FunctionHeader>
                                <label>一般ユーザー</label>
                                <ButtonEdit type="button" onClick={() => setSelectUserModalOpen(true)}>
                                    編集
                                </ButtonEdit>
                            </FunctionHeader>

                            <SimpleList>
                                {users.map((user) => (
                                    <SimpleListItem key={user.id}>
                                        <SelectUser value={String(user.id)} disabled={false} />
                                        <ItemSummaryPop
                                            popTitle={<SelectUser value={String(user.id)} disabled={false} />}
                                            popMain={<div>一般ユーザー詳細 未取得</div>}
                                            linkDetail={`/users/${user.id}/edit`}
                                        />
                                    </SimpleListItem>
                                ))}
                            </SimpleList>
                        </li>
                        <li>
                            <label htmlFor="fileServer">ファイルサーバー</label>
                            <input
                                type="text"
                                value={fileServer}
                                id="fileServer"
                                onChange={(e) => setFileServer(e.target.value)}
                            />
                        </li>
                        <li>
                            <label htmlFor="endpoint">エンドポイント(アップロード時のファイルサーバーホスト名が異なる場合のみ)</label>
                            <input
                                type="text"
                                value={endpoint}
                                id="endpoint"
                                onChange={(e) => setEndpoint(e.target.value)}
                            />
                        </li>
                    </InputBasic>
                </section>

                <section>
                    <SectionTitle>月次請求ルール</SectionTitle>
                    <InputMonthly>
                        {billingRules.map((rule, i) => (
                            <li key={i}>
                                <input type="text" value={rule.name} disabled />
                                <input type="text" value={rule.remarks} disabled />
                                <input type="text" value={"上限" + rule.contentsMax} disabled />
                                <div className="buttons">
                                    <ButtonEdit type="button" onClick={() => selectBillingRuleIndex(i)}>
                                        編集
                                    </ButtonEdit>
                                    <ButtonDelete type="button" onClick={() => removeBillingRule(i)}>
                                        削除
                                    </ButtonDelete>
                                </div>

                                {organization && rule.id && (
                                    <OrganizationBillingDataDownloadForm
                                        organizationId={organization.id}
                                        billingRuleId={rule.id}
                                    />
                                )}
                            </li>
                        ))}
                    </InputMonthly>
                    <div className="addButtonArea">
                        <ButtonAddRule variant="contained" disableElevation onClick={addBillingRule}>
                            +
                        </ButtonAddRule>
                    </div>

                    {/* <hr /> */}
                    {children}
                </section>
            </FormModified>

            {/* Modal表示準備 */}
            <SelectOrganizationModal
                open={selectOrganizationModalOpen}
                value={organizationChildren}
                onChange={setOrganizationChildren}
                onClose={() => setSelectOrganizationModalOpen(false)}
                organizationId={organization?.id || 0}
            />
            <SelectUserModal
                open={selectManagerModalOpen}
                value={managers}
                userType={"テナント管理者"}
                onChange={setManagers}
                onClose={() => setSelectManagerModalOpen(false)}
            />
            <SelectUserModal
                open={selectUserModalOpen}
                value={users}
                userType={"一般ユーザー"}
                onChange={setUsers}
                onClose={() => setSelectUserModalOpen(false)}
            />
            {selectedBillingRuleIndex >= 0 && (
                <BillingRuleEditModal
                    open
                    data={billingRules[selectedBillingRuleIndex]}
                    onChange={(data) => changeBillingRule(data)}
                    onClose={() => selectBillingRuleIndex(-1)}
                />
            )}
        </>
    );
}
