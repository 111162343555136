import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { Id } from "./id";
import { GetOrganizationsResponse, Organization, OrganizationForm, OrganizationQuery } from "./organization";
import { Page } from "./page";

export const organizationApi = createApi({
    reducerPath: "organizationApi",
    baseQuery,
    tagTypes: ["Organization"],
    endpoints: (builder) => ({
        getOrganization: builder.query<Organization, Id>({
            query: (id) => `organizations/${id}`,
            providesTags: ["Organization"],
        }),
        getOrganizations: builder.query<Page<Organization>, OrganizationQuery | void>({
            query: (params) => ({ url: "organizations/list", params: params || undefined }),
            providesTags: ["Organization"],
        }),
        getOrganizationChildren: builder.query<GetOrganizationsResponse, Id | void>({
            // idがない場合は最上位の階層を取得する
            query: (id) => (id ? `organization-children/${id}` : "organization-children"),
            providesTags: ["Organization"],
        }),
        getOrganizationDeliveryChildren: builder.query<GetOrganizationsResponse, Id | void>({
            // 子テナントがない場合、idのテナント情報を返す
            query: (id) => (id ? `organization-delivery-children/${id}` : "organization-delivery-children"),
            providesTags: ["Organization"],
        }),
        createOrganization: builder.mutation<void, OrganizationForm>({
            query: (body) => ({
                url: `organizations`,
                method: "post",
                body,
            }),
            invalidatesTags: ["Organization"],
        }),
        updateOrganization: builder.mutation<void, OrganizationForm>({
            query: ({ id, ...body }) => ({
                url: `organizations/${id}`,
                method: "post",
                body,
            }),
            invalidatesTags: ["Organization"],
        }),
        deleteOrganizations: builder.mutation<void, number[]>({
            query: (ids) => ({
                url: `organizations`,
                body: {
                    organizationId: ids,
                },
                method: "DELETE",
            }),
            invalidatesTags: ["Organization"],
        }),
    }),
});

export const {
    useGetOrganizationQuery,
    useGetOrganizationsQuery,
    useGetOrganizationChildrenQuery,
    useGetOrganizationDeliveryChildrenQuery,
    useCreateOrganizationMutation,
    useUpdateOrganizationMutation,
    useDeleteOrganizationsMutation,
} = organizationApi;
